    import './scss/main.scss'
    const screenHeight = () => {
        var vh = window.innerHeight;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    const mq = window.matchMedia('(max-width: 840px)');

    function init(){

    }

    window.onload = (event) => {
        console.log('page is fully loaded');
        init();
        screenHeight();
    };

    window.addEventListener('resize', screenHeight());


    // Función para aplicar la posición x de un elemento a otro
    function applyPositionX(sourceSelector, targetSelector) {
        // Seleccionamos los elementos del DOM
        let sourceElement = document.querySelector(sourceSelector);
        let targetElement = document.querySelector(targetSelector);

        // Obtenemos la posición x del elemento fuente
        let sourcePositionX = sourceElement.getBoundingClientRect().left;

        // Aplicamos la posición x al elemento objetivo
        targetElement.style.left = `${sourcePositionX}px`;
    }

    // Llamar a las funciones cuando la ventana se carga y cuando se redimensiona
    window.onload = window.onresize = function(){
        applyPositionX('.logo .letter-i1', '.header__brand--name');
        applyPositionX('.logo .letter-i2', '.header__menu--item.item-i2');
        applyPositionX('.logo .letter-x', '.header__menu--item.item-x');
    }
